/* eslint-disable quotes */
import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import Select from 'ui/Select/Select'
import Text from '../../locale/strings'
import TextField from '../../ui/TextField/TextField'
import { CSVLink } from 'react-csv'

import { OptionType } from 'common/api/common/common'

import useStores from '../../common/hook/useStore'
import { usePlatforsmListQuery } from '../../platforms/PlatformsStore'

import styles from './csvModal.module.scss'

interface ModalProps {
  open: boolean
  dataTable: any
  columns: any
  handleClose: () => void
}

const toPlatformsOptions = (item: any): OptionType => ({
  label: item.name,
  value: item.id,
})

const CsvModal: React.FC<ModalProps> = ({ open, dataTable, columns, handleClose }: ModalProps) => {
  const { platformsStore } = useStores()

  const [fileName, setFileName] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [dataByPlatform, setDataByPlatform] = useState<any>(null)

  const { data: platformsData } = usePlatforsmListQuery(platformsStore)
  const platformsTypes = platformsData ?? []
  const platformsOptions: OptionType[] = platformsTypes.map(toPlatformsOptions)
  const [platformId, setPlatformId] = useState<string>('')

  const handleChange = (value: string) => {
    if (value.length <= 151) {
      setError(false)
    }

    if (value.length >= 151) {
      setError(true)

      return
    }

    setFileName(value)
  }

  const csvReport = {
    data: dataByPlatform,
    headers: columns,
    filename: `${fileName}.csv`,
  }

  const formatMetadata = (selectedPlatform: string) => {
    return dataTable.map((dataTableItem: any) => {
      const metadata = dataTableItem[dataTableItem.length - 1]

      if (metadata && metadata.hasOwnProperty(selectedPlatform)) {
        return [...dataTableItem.slice(0, -1), metadata[selectedPlatform]]
      }

      return [...dataTableItem.slice(0, -1), 'NULL']
    })
  }

  const handlePlatformChange = async (platformOption: string) => {
    const formattedData = await formatMetadata(platformOption)

    setDataByPlatform(formattedData)
    setPlatformId(platformOption)
  }

  return (
    <div className={styles.holder}>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <React.Fragment>
          <Box>
            <div className={styles.container}>
              <div className={styles.header}>{'Download CSV'}</div>
              <div className={styles.inputHolder}>
                <Select
                  fullWidth
                  data-test="platforms-field"
                  label={'Platforms'}
                  value={platformId}
                  options={platformsOptions}
                  className={styles.select}
                  onChange={handlePlatformChange}
                />
              </div>
              <div className={styles.inputHolder}>
                <label className={styles.label}>File name:</label>
                <TextField
                  size="small"
                  value={fileName}
                  error={error ? Text.errors.maxLength150 : undefined}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </div>
              <div className={styles.submitContainer}>
                <Button variant={ButtonVariant.OUTLINED} onClick={() => handleClose()}>
                  {Text.common.cancel}
                </Button>
                {fileName === '' || platformId === '' ? (
                  <Button disabled color={ButtonColor.INFO}>
                    {Text.common.save}
                  </Button>
                ) : (
                  <CSVLink
                    {...csvReport}
                    enclosingCharacter={``}
                    className={styles.csvButton}
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    <Button color={ButtonColor.PRIMARY}>{Text.common.save}</Button>
                  </CSVLink>
                )}
              </div>
            </div>
          </Box>
        </React.Fragment>
      </Modal>
    </div>
  )
}

export default CsvModal
