/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import useStores from '../../common/hook/useStore'
import { usePlatforsmListQuery } from '../../platforms/PlatformsStore'

import LoadingButton from '@mui/lab/LoadingButton'
import Modal from '@mui/material/Modal'

import Text from '../../locale/strings'
import Select from 'ui/Select/Select'
import Button, { ButtonVariant } from '../Button/Button'

import TextField from '../TextField/TextField'
import API from 'common/api'
import { JsonMetadataModalDataTypes } from 'common/constants'
import { OptionType } from 'common/api/common/common'

import { UpdateMetadataRequest } from '../../common/api/styng/styng'
import { UpdateTrackExternalMetadataRequest, UpdateRoyaltyFreeTrackMetadataRequest } from '../../common/api/track/track'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from './JsonDataModalWithPlatform.module.scss'

interface JsonDataModalWithPlatformProps {
  open: boolean
  dataTypeId: string | undefined
  data: any
  dataType: string
  refetch: () => void
  handleClose: () => void
  appId?: string
}

const toPlatformsOptions = (item: any): OptionType => ({
  label: item.name,
  value: item.id,
})

const JsonDataModalWithPlatform = ({
  open,
  appId,
  dataTypeId,
  data,
  dataType,
  refetch,
  handleClose,
}: JsonDataModalWithPlatformProps) => {
  const { notificationsStore, platformsStore } = useStores()
  const [metadataObject, setMetadataObject] = useState<any>([])
  const [metadataArray, setMetadataArray] = useState<any>([])
  const [showErrorOnSave, setShowErrorOnSave] = useState<boolean>(false)

  const { data: platformsData } = usePlatforsmListQuery(platformsStore)
  const platformsTypes = platformsData ?? []
  const platformsOptions: OptionType[] = platformsTypes.map(toPlatformsOptions)
  const [platformId, setPlatformId] = useState<string>('')

  useEffect(() => {
    const formattedObj = { ...data }

    if (Object.keys(formattedObj).length !== 0) {
      for (const key in formattedObj) {
        try {
          const parsedValue = JSON.parse(formattedObj[key])
          const helperMetadataArray = []

          for (const key in parsedValue) {
            if (parsedValue.hasOwnProperty(key)) {
              helperMetadataArray.push({
                key: key,
                value: parsedValue[key],
              })
            }
          }

          formattedObj[key] = helperMetadataArray
        } catch (error) {
          console.warn(error)
        }
      }
    }

    setMetadataObject(formattedObj)
  }, [data])

  const updateMetadataMutation = useMutation<string, Error, UpdateMetadataRequest>(
    (body: UpdateMetadataRequest) => {
      return API.styngs.updateMetadata(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const updateExternalMetadataMutation = useMutation<string, Error, UpdateTrackExternalMetadataRequest>(
    (body: UpdateTrackExternalMetadataRequest) => {
      return API.track.updateTrackExternalMetadaData(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const updateRoyaltyFreeExternalMetadataMutation = useMutation<string, Error, UpdateRoyaltyFreeTrackMetadataRequest>(
    (body: UpdateRoyaltyFreeTrackMetadataRequest) => {
      return API.track.updateRoyaltyFreeTrackExternalMetadaData(body)
    },
    {
      onSuccess: () => {
        notificationsStore.successNotification(Text.jsonDataModal.successText)
        refetch()
        handleClose()
      },
      onError: (error) => {
        const errorData = JSON.stringify(error)
        const parsedError = JSON.parse(errorData)

        notificationsStore.errorNotification(parsedError.errorMessage)
      },
    },
  )

  const onAddClick = () => {
    setMetadataArray([...metadataArray, { key: '', value: '' }])
  }

  const onDeleteClick = (key: any) => {
    setMetadataArray((metadataArray: any) =>
      metadataArray.filter((_item: { [key: string]: string | number }, index: number) => index !== key),
    )
  }

  const handleInputUpdate = (type: string, e: any, index: number) => {
    const newValue = e.target.value

    setMetadataArray((prevArray: any) =>
      prevArray.map((item: any, i: number) => (i === index ? { ...item, [type]: newValue } : item)),
    )
  }

  useEffect(() => {
    setMetadataObject((prevState: any) => ({
      ...prevState,
      [platformId]: metadataArray,
    }))
  }, [metadataArray])

  const handlePlatformChange = (platformOption: string) => {
    setMetadataArray([])

    setPlatformId(platformOption)
  }

  useEffect(() => {
    if (metadataObject.hasOwnProperty(platformId)) {
      setMetadataArray([...metadataObject[platformId]])
    }
  }, [platformId])

  const renderJsonRows = () => {
    return metadataArray.map((item: { [key: string]: string | number }, index: number) => (
      <div className={styles.rowContent} key={index}>
        <TextField
          className={styles.textField}
          label={'Key'}
          name="key"
          value={item.key}
          onChange={(e) => handleInputUpdate('key', e, index)}
        />
        <TextField
          className={styles.textField}
          label={'Value'}
          name="value"
          value={item.value}
          onChange={(e) => handleInputUpdate('value', e, index)}
        />
        <DeleteIcon color={'action'} className={styles.deleteIcon} onClick={() => onDeleteClick(index)} />
      </div>
    ))
  }

  const hasEmptyKey = async (obj: any) => {
    for (const arrayKey in obj) {
      if (obj.hasOwnProperty(arrayKey)) {
        const arrayOfObjects = obj[arrayKey]

        const hasEmpty = arrayOfObjects.some((item: any) => item.key === '')

        if (hasEmpty) {
          setShowErrorOnSave(true)

          return true
        }
      }
    }

    setShowErrorOnSave(false)

    return false
  }

  const handleSubmit = async () => {
    let jsonForSave: { [key: string]: any } = {}

    const showError = await hasEmptyKey(metadataObject)

    if (showError) {
      return
    }

    if (Object.keys(metadataObject).length === 0) {
      jsonForSave = {}
    } else {
      for (const platformIdValue in metadataObject) {
        if (platformIdValue) {
          const formattedValues = metadataObject[platformIdValue].reduce((acc: any, obj: any) => {
            acc[obj.key] = obj.value

            return acc
          }, {})

          jsonForSave[platformIdValue] = JSON.stringify(formattedValues)
        }
      }
    }

    if (dataType === JsonMetadataModalDataTypes.STYNG) {
      const body = {
        styngId: dataTypeId,
        metadata: jsonForSave,
      }

      updateMetadataMutation.mutate(body)
    } else if (dataType === JsonMetadataModalDataTypes.TRACK) {
      const body = {
        appId: appId,
        trackMediaNetId: dataTypeId,
        metadata: jsonForSave,
      }

      updateExternalMetadataMutation.mutate(body)
    } else if (dataType === JsonMetadataModalDataTypes.ROYALTY_FREE_TRACK) {
      const body = {
        appId: appId,
        trackId: dataTypeId,
        metadata: jsonForSave,
      }

      updateRoyaltyFreeExternalMetadataMutation.mutate(body)
    }
  }

  return (
    <React.Fragment>
      {<div className={styles.backdropDiv} />}
      <Modal hideBackdrop open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className={styles.container}>
          <div className={styles.header}>{Text.jsonDataModal.enterMetadata}</div>
          <div className={styles.body}>
            <Select
              fullWidth
              data-test="platforms-field"
              label={'Platforms'}
              value={platformId}
              options={platformsOptions}
              className={styles.select}
              onChange={handlePlatformChange}
            />
            {platformId === '' ? (
              <div className={styles.noPlatformWrapper}>Select platform from the list</div>
            ) : (
              <React.Fragment>
                <div className={styles.rowWrapper}>{renderJsonRows()}</div>
                <div className={styles.addNewwWrapper}>
                  <div className={styles.addButtonRow}>
                    <LoadingButton
                      size="small"
                      color="primary"
                      variant="outlined"
                      endIcon={<AddIcon />}
                      onClick={() => onAddClick()}
                    >
                      <span>{Text.common.add}</span>
                    </LoadingButton>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className={styles.submitContainer}>
            {showErrorOnSave && (
              <div className={styles.errorInfoBox}>
                <p>{Text.jsonDataModal.errorTextKeyIsMissing}</p>
              </div>
            )}
            <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
              {Text.common.cancel}
            </Button>
            <Button onClick={handleSubmit}>{Text.common.save}</Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default JsonDataModalWithPlatform
